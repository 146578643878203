import { Environment } from "@Shared/models/environment.model";
import { Config } from '../app/configs/index.config';

import { environment as env } from 'projects/evolutics-shared-lib/src/environments/environment'; 
import { configMainMenu } from '../app/configs/menu-configs/top-menu.config';
import { configClientMainMenu } from "projects/evolutics-admin-ui/src/app/configs/menu-configs/client-top-menu.config";
env.apiBaseUrl = Config.APIStagingServer;
env.debug = true;
env.authenticate = true;
env.menus = configMainMenu;
env.clientMenus = configClientMainMenu;
env.versionNo="0.0.4";
export const environment = env;
