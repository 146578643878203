import { IMenuItem, MenuItem } from '@Shared/models/IMenuItem';

const _configClientMainMenu: IMenuItem[] = [
  // {
  //   label: 'Administration',
  //   icon: 'evoicon evo-admin',
  //   subs: [
  //     {
  //       label: 'Users',
  //       subs: [
  //         { label: 'User', link: '/admin/user' },
  //         { label: 'User Menu', link: '/admin/menus' },
  //         { label: 'User Group', link: '/admin/userGroup' },
  //       ],
  //     },
  //     {
  //       label: 'Release Management',
  //       subs: [
  //         { label: 'Create Release', link: '/admin/release/create' },
  //         { label: 'Find Release', link: '/admin/release/find' },
  //       ],
  //     },
  //     {
  //       label: 'Companies',
  //       icon: 'fa fa-institution',
  //       link: '/company',
  //       id: 'MA7',
  //     },
  //     {
  //       label: 'Projects',
  //       icon: 'fa fa-industry',
  //       link: '/project/create',
  //       id: 'MA7',
  //     },
  //     {
  //       label: 'Platforms',
  //       icon: 'fa fa-server',
  //       subs: [
  //         {
  //           label: 'Application Home',
  //           link: '/application',
  //         },
  //         {
  //           label: 'Find Application',
  //           link: '/application/find',
  //         },
  //       ],
  //       id: 'MA7',
  //     },
  //   ],
  //   id: 'MA1',
  // },
  // {
  //   label: 'Libraries',
  //   subs: [
  //     {
  //       label: 'Administrator',
  //       subs: [
  //         {
  //           label: 'Find API Library',
  //           link: '/api',
  //         },
  //         {
  //           label: 'Create API Library',
  //           link: '/api/create',
  //         },
  //         {
  //           label: 'Find Database Library',
  //           link: '/db',
  //         },
  //         {
  //           label: 'Create Database Library',
  //           link: '/db/create',
  //         },
  //         {
  //           label: 'Find FAQ Library',
  //           link: '/faq',
  //         },
  //         {
  //           label: 'Create FAQ Library',
  //           link: '/faq/create',
  //         },
  //       ],
  //     },
  //     {
  //       label: 'Client',
  //       subs: [
  //         {
  //           label: 'API Library',
  //           link: '/api/client/show',
  //           id: 'MVAL2',
  //         },
  //         {
  //           label: 'API Library New',
  //           link: '/client-libraries/api',
  //           id: 'MVFL4',
  //         },
  //         {
  //           label: 'Database Library',
  //           link: '/db/client',
  //           id: 'MVDL3',
  //         },
  //         {
  //           label: 'Database Library New',
  //           link: '/client-libraries/db',
  //           id: 'MVFL4',
  //         },
  //         {
  //           label: 'FAQ Library',
  //           link: '/client/faq',
  //           id: 'MVFL4',
  //         },
  //         {
  //           label: 'FAQ Library New',
  //           link: '/client-libraries/faq',
  //           id: 'MVFL4',
  //         },
  //       ],
  //       id: 'MC3',
  //     },
  //   ],
  //   id: 'ML2',
  // },
  // {
  //   label: 'Reports',
  //   icon: 'evoicon evo-blankdoc',
  //   subs: [
  //     {
  //       label: 'Create/Run',
  //       link: '/reports',
  //     },
  //     {
  //       label: 'Batch',
  //       link: '/reports/batch',
  //     },
  //   ],
  //   id: 'MR5',
  // },
  // {
  //   label: 'Analytics',
  //   icon: 'fa fa-chart-bar',
  //   subs: [
  //     {
  //       label: 'Team Performance',
  //       link: '/analytics/team-performance',
  //     },
  //     {
  //       label: 'Task O/S SLA',
  //       link: '/analytics/task-os-sla',
  //     },
  //   ],
  //   id: 'MA7',
  // },
  // {
  //   label: 'Set Up',
  //   subs: [
  //     { label: 'Code Setup', id: 'MC6', link: '/setup/codes/parameters' },
  //     { label: 'Task Setup', id: 'MTS5', link: '/task/setup' },
  //   ],
  //   id: 'MSU4',
  // },
  {
    label: 'Workflows',
    icon: 'fas fa-tasks ',
    subs: [
       {
        label: 'Administrator',
        subs: [
           {
              label: 'Bulk Create Workflow',
              link: '/task/workflow/bulk-create-workflow',
           },
           {
              label: 'Create New Task',
              link: '/task/workflow/create',
           },
           {
            label: 'Find Task',
            link: '/task/workflow/find',
           },
        ]
        },
        {
        label: 'Client',
        subs: [
           {
              label: 'Bulk Create Workflow',
              link: '/task/client/workflow/bulk-create-workflow',
           },
           {
              label: 'Create New Task',
              link: '/task/client/workflow/create',
           },
           {
            label: 'Find Task',
            link: '/task/client/workflow/find',
           },
        ]
        },
    ],
    id: 'MSU4',
  },
];

export const configClientMainMenu = _configClientMainMenu
  ?.map((m) => new MenuItem(m))
  .sort2('label', true);

// console.log('MENUITEMS', configClientMainMenu);
// debugger
for (const moduleMenu of configClientMainMenu) {
  moduleMenu.icon = undefined;
  moduleMenu.subs.forEach((m) => {
    m.icon = undefined;
    m['subsLength'] = m.subs?.length||0;
  });
  moduleMenu.subs = moduleMenu.subs.sort2('label', true);
  moduleMenu.subs = moduleMenu.subs.sort2('subs', false,true);
  // debugger;
  for (let i = 0; i < moduleMenu?.subs?.length; i++) {
    const mi = moduleMenu?.subs[i];
    mi.submenuPosition = moduleMenu?.subs?.length / 2 > i ? 'after' : 'before';
  }
  moduleMenu.systemIcon = moduleMenu.icon;
}
// let j = 1;
// function getID(m: MenuItem) {
//   return (
//     'M' +
//     m.label
//       ?.split(' ')
//       ?.map((x) => x[0])
//       ?.join('') +
//     j++
//   );
// }
// function idier(m: any) {
//   m.id = getID(m);
//   if (m.subs)
//     for (const menu of m.subs) {
//       idier(menu);
//     }
// }
// for (const m of configClientMainMenu) {
//   idier(m);
// }
