import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ChatHelpDeskComponent } from '../../../evolutics-shared-lib/src/lib/Shared/components/page-icons/chat-help-desk/chat-help-desk.component';
import { LoaderComponent } from 'ets-fe-ng-sdk';
import { MatProgressBar } from '@angular/material/progress-bar';
import { SharedAppComponent } from 'projects/evolutics-shared-lib/src/lib/shared-app.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [MatProgressBar, LoaderComponent, RouterOutlet, ChatHelpDeskComponent],
})
export class AppComponent extends SharedAppComponent {}
