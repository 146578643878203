import { inject } from '@angular/core';
import { Router, type CanActivateChildFn } from '@angular/router';
import { AdminUserService } from '../Services/admin-user.service';

export const authGuard: CanActivateChildFn = (childRoute, state) => {
  const router = inject(Router);
  const adminS = inject(AdminUserService);
  console.log(adminS.getFromLocal());
  if (adminS.getFromLocal()) {
    return true;
  } else {
    router.navigate(['auth/login'], {
      queryParams: { r: location.href.replace(location.origin, '') },
    });
    return false;
  }
};
